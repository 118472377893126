import React, { Suspense, lazy, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import IntroArea from "@components/introarea/layout-1";
import PartnerArea from "@containers/partner/layout-01";
import BoxSection from "@components/BoxSection/layout-one/layout-3";
import CtaArea from "@containers/cta/layout-07";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import CaseStudy from "@containers/global/case-study/layout-01";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const MultilingualSeoServicesPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 20) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Multilingual SEO Services | Multilingual SEO Translation"
        description="Scalable, cost-effective multilingual SEO services to increase your brand's online visibility while enhancing search rankings across search engines."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile,
        }}
      />
   
      <main className="site-wrapper-reveal">
        <PageHeader data={content["multilingual-seo-page-header-section"]} />
        <PartnerArea data={content["multilingual-seo-logo"]} />
        <FeatureGrid data={content["multilingual-seo-features"]} />
        {
          (!isMobile || (isMobile && showRestComponents)) && <IntroArea layout={2} data={content["multilingual-seo-intro-body"]} />
        }
        {
          showRestComponents && <>
           <BoxSection data={content["multilingual-seo-localize-body"]} />
        <CtaArea data={content["multilingual-seo-cta-data"]} />
        <IntroAreaReverse layout={2} data={content["multilingual-seo-reverse-intro"]} />
        <CaseStudy data={caseStudiesData} />
        <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};
export const query = graphql`
  query MultilingualSeoServicesPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "multilingual-seo" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;

MultilingualSeoServicesPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default MultilingualSeoServicesPage;
