import { useEffect } from "react";
const UseScroll = (onScroll, setIsMobile) => {
  useEffect(() => {
    const handleScroll = () => {
      if (typeof onScroll === "function") {
        onScroll(window.scrollY);
      }
    };
    const handleResize = () => {
      if (typeof setIsMobile === "function") {
        setIsMobile(window.innerWidth <= 412);
      }
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [onScroll, setIsMobile]);
};

export default UseScroll;
